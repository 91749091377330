import React from 'react';

export default function SearchComponent(props) {

    const [product_code, setProductCode] = React.useState('')

    const handleChangeProductCode = (e) => {
        setProductCode(e.target.value)
    }

    const handleClickProduct = (code) => {
        if (code === '750396-UAAAY-1092') {
            props.changeComponent('product_code', 0)
            window.history.pushState({ page: "display_page" }, "display_page", '/mockup/750396-UAAAY-1092');
        } else if (code === '746939-AAB62-5343') {
            props.changeComponent('product_code', 1)
            window.history.pushState({ page: "display_page" }, "display_page", '/mockup/746939-AAB62-5343');
        }
        else if (code === '676610-4G200-1061') {
            props.changeComponent('product_code', 2)
            window.history.pushState({ page: "display_page" }, "display_page", '/mockup/676610-4G200-1061');
        } else
            props.changeComponent('search', 3)
    }

    return (
        <div className="search_component">
            <div className="search">
                <div className="search_container">

                    <div className="search_input_title_container">
                        <div className="search_title">Find the digital passport of your product here</div>

                        <div className="search_input_container">

                            <div className="search_input_title" >Type here the code of your product</div>
                            <div className="search_input_cont">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-search pointer search_input_icon" viewBox="0 0 16 16" onClick={() => handleClickProduct(product_code)}>
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                            </div>
                            <input type='text' className="search_input" onChange={(e) => handleChangeProductCode(e)} />
                        </div>


                        {props.notFound ?
                            <div className="product_auth_container">
                                <div className="product_auth" Style={"text-align: -webkit-left;"}>{props.notFoundSmc}</div>
                                <div className="product_auth">Check that the entered code is correct and try again.</div>

                            </div>
                            : ''}
                    </div>
                </div>
            </div>
        </div >)
}