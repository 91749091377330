import React, { Component } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import { Collapse } from 'react-collapse';

export default function Footer(props) {

    const [isOpenHelp, setIsOpenHelp] = React.useState(false)
    const [isOpenExecutive, setIsOpenExecutive] = React.useState(false)
    const [isOpenCompany, setIsOpenCompany] = React.useState(false)
    const [isOpenFindUs, setIsOpenFindUss] = React.useState(false)

    const setOpenCollapse = (val) => {
        switch (val) {
            case 'help':
                setIsOpenHelp(!isOpenHelp)
                setIsOpenCompany(false)
                setIsOpenCompany(false)
                setIsOpenFindUss(false)
                break;
            case 'executive':
                setIsOpenExecutive(!isOpenExecutive)
                setIsOpenHelp(false)
                setIsOpenCompany(false)
                setIsOpenFindUss(false)
                break;
            case 'company':
                setIsOpenHelp(false)
                setIsOpenExecutive(false)
                setIsOpenCompany(!isOpenCompany)
                setIsOpenFindUss(false)
                break;
            case 'find_us':
                setIsOpenHelp(false)
                setIsOpenExecutive(false)
                setIsOpenCompany(false)
                setIsOpenFindUss(!isOpenFindUs)
                break;
            default:
                break;
        }
    }

    return (
        <div className="footer_container">

            <div className="footer footer_web">


                <React.Fragment>
                    <div className="footer_columns_container flex">
                        <div id="help_column">
                            <div className="title_menu_footer">May we help you?</div>
                            <div className="submenu_footer pointer">Contact us</div>
                            <div className="submenu_footer pointer">My Orders</div>
                            <div className="submenu_footer pointer">FAQs</div>
                            <div className="submenu_footer pointer">Email Unsubscribe</div>
                            <div className="submenu_footer pointer">Sitemap</div>
                        </div>
                        <div id="company_column">
                            <div className="title_menu_footer">Company</div>

                            <div className="submenu_footer pointer">About Gucci</div>
                            <div className="submenu_footer pointer">Gucci Equilibrium</div>
                            <div className="submenu_footer pointer">Accessibility</div>
                            <div className="submenu_footer pointer">Code of Ethics</div>
                            <div className="submenu_footer pointer">Carrers</div>
                            <div className="submenu_footer pointer">Legal</div>
                            <div className="submenu_footer pointer">Privacy & Cookies</div>
                            <div className="submenu_footer pointer">Corporate information</div>
                        </div>
                        <div className="half_column">
                            <div className="title_menu_footer">Find a Gucci Store</div>
                            <div>
                                <input className="search_input_footer pointer" placeholder="City, Region, Country" ></input>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#555" class="bi bi-chevron-right search_input_footer_arrow" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </div>
                            <div className="title_menu_footer" Style={"margin-top: 30px;"}>Sign up for emails</div>
                            <div>
                                <div Style={'color: #cfcfcf; font-size: 12px; margin-top: 10px;'}>
                                    Inserendo il tuo indirizzo e-mail, acconsenti a ricevere le newsletter Gucci relative alle ultime collezioni, agli eventi e alle campagne del brand. Per maggiori informazioni consulta la nostra <span Style="text-decoration: underline;">Informativa a tutela della privacy</span>.
                                </div>
                                <input className="search_input_footer pointer" placeholder="Email" ></input>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#555" class="bi bi-chevron-right search_input_footer_arrow" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </div>
                            <div className="title_menu_footer" Style={"margin-top: 40px;"}>Language</div>
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    Italian
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Italian</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">English</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Spanish</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>



                        </div>
                    </div>
                    <div className="flex">
                        <div className="half_column">
                            <div className="title_menu_footer">Exclusive Services</div>
                            <div className="submenu_footer pointer">Bespoke Online Services</div>
                            <div className="submenu_footer pointer">Book an Appointment</div>
                        </div>
                        <div className="half_column">
                            <div className="title_menu_footer" Style={"margin-top: 40px;"}>Shipping country</div>
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    Italy
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Italy</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">United Kingdom</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Spain</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="flex" Style={"margin-top: 40px;"}>
                        <div id="gucci_equilibrium_logo"></div>
                        <div id="gucci_app_logo"></div>
                        <div id="gucci_garden_logo"></div>
                        <div id="gucci_osteria_logo"></div>
                    </div>
                </React.Fragment>
                <div id="license_string_footer">
                    © 2016 - 2021 Guccio Gucci S.p.A. - Tutti i Diritti Riservati. G Commerce Europe S.p.A. - IT VAT nr 05142860484. LICENZA SIAE N. 2294/I/1936 e 5647/I/1936
                </div>

                <div id="footer_logo"></div>
            </div >
            <div className="footer footer_mobile">

                <div className="title_menu_footer">Find a Gucci Store</div>
                <div className="flex align_items">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" class="bi bi-geo-alt" viewBox="0 0 16 16">
                        <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                        <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                    </svg>
                    <input className="search_input_footer pointer" placeholder="City, Region, Country" ></input>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#fff" class="bi bi-chevron-right search_input_footer_arrow" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </div>
                <div className="title_menu_footer" Style={"margin-top: 30px;"}>Sign up for emails</div>

                <div Style={'color: #cfcfcf; font-size: 12px; margin-top: 10px;'}>
                    Inserendo il tuo indirizzo e-mail, acconsenti a ricevere le newsletter Gucci relative alle ultime collezioni, agli eventi e alle campagne del brand. Per maggiori informazioni consulta la nostra <span Style="text-decoration: underline;">Informativa a tutela della privacy</span>.
                </div>
                <div className="flex align_items">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" class="bi bi-envelope" viewBox="0 0 16 16">
                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                    </svg>
                    <input className="search_input_footer pointer" placeholder="Email" ></input>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#fff" class="bi bi-chevron-right search_input_footer_arrow" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </div>

                <div Style={"margin-top: 40px; margin-bottom: 40px;"}>
                    <div className={isOpenHelp ? "collapse_container" : ''}>
                        <div className="pointer" onClick={() => setOpenCollapse('help')}>
                            <input className={isOpenHelp ? "collapse_button_input_footer_open" : 'collapse_button_input_footer_closed'} placeholder="May we help you?" ></input>
                            {isOpenHelp ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#fff" class="bi bi-chevron-up collapse_arrow_opened" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
                                </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#fff" class="bi bi-chevron-down collapse_arrow" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                </svg>
                            }
                        </div>
                        <Collapse isOpened={isOpenHelp}>
                            <div className="submenu_footer pointer">Contact us</div>
                            <div className="submenu_footer pointer">My Orders</div>
                            <div className="submenu_footer pointer">FAQs</div>
                            <div className="submenu_footer pointer">Email Unsubscribe</div>
                            <div className="submenu_footer pointer">Sitemap</div>
                        </Collapse>
                    </div>
                    <div className={isOpenExecutive ? "collapse_container" : ''}>
                        <div className="pointer" onClick={() => setOpenCollapse('executive')}>
                            <input className={isOpenExecutive ? "collapse_button_input_footer_open" : 'collapse_button_input_footer_closed'} placeholder="Executive services" ></input>
                            {isOpenExecutive ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#fff" class="bi bi-chevron-up collapse_arrow_opened" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
                                </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#fff" class="bi bi-chevron-down collapse_arrow" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                </svg>
                            }
                        </div>
                        <Collapse isOpened={isOpenExecutive}>
                            <div className="submenu_footer pointer">Bespoke Online Services</div>
                            <div className="submenu_footer pointer">Book an Appointment</div>
                        </Collapse>
                    </div>

                    <div className={isOpenCompany ? "collapse_container" : ''}>
                        <div className="pointer" onClick={() => setOpenCollapse('company')}>
                            <input className={isOpenCompany ? "collapse_button_input_footer_open" : 'collapse_button_input_footer_closed'} placeholder="The company" ></input>
                            {isOpenCompany ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#fff" class="bi bi-chevron-up collapse_arrow_opened" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
                                </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#fff" class="bi bi-chevron-down collapse_arrow" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                </svg>
                            }
                        </div>
                        <Collapse isOpened={isOpenCompany}>
                            <div className="submenu_footer pointer">About Gucci</div>
                            <div className="submenu_footer pointer">Accessibility</div>
                            <div className="submenu_footer pointer">Code of Ethics</div>
                            <div className="submenu_footer pointer">Carrers</div>
                            <div className="submenu_footer pointer">Legal</div>
                            <div className="submenu_footer pointer">Privacy & Cookies</div>
                            <div className="submenu_footer pointer">Corporate information</div>
                        </Collapse>
                    </div>
                    {/* 
                    <div className={isOpenFindUs ? "collapse_container" : ''}>
                        <div className="pointer" onClick={() => setOpenCollapse('find_us')}>
                            <input className={isOpenFindUs ? "collapse_button_input_footer_open" : 'collapse_button_input_footer_closed'} placeholder="Find Us" ></input>
                            {isOpenFindUs ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#fff" class="bi bi-chevron-up collapse_arrow_opened" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
                                </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#fff" class="bi bi-chevron-down collapse_arrow" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                </svg>
                            }
                        </div>
                        <Collapse isOpened={isOpenFindUs}>
                            <div className="submenu_footer pointer">Bespoke Online Services</div>
                            <div className="submenu_footer pointer">Book an Appointment</div>
                        </Collapse>
                    </div>
                    */}
                </div>
                <div id="footer_logo_mobile"></div>

            </div>
        </div>
    )
}