import './App.css';
import Cookies from "universal-cookie";
import { useIsAuthenticated } from "@azure/msal-react";
import { loginRequest, msalInstance } from "./authConfig";
import { BrowserRouter } from "react-router-dom";
import MyRoutes from './MyRoutes';
import React, { useState } from 'react';
import MyRoutesMockup from './MyRoutesMockup';


function App() {


  let mockup = false
  let isAuthenticated = false;
  const isSsoAuthenticated = useIsAuthenticated();
  if (window.location.pathname.startsWith('/mockup')) {
    mockup = true
  } else {
    const cookies = new Cookies();

    //check if the user is authenticated
    if (cookies.get('user_token') === undefined) {

      if (isSsoAuthenticated) {
        cookies.set('user_token', 'temp', { path: '/', maxAge: 31536000 });
        cookies.set('user_token_type', 'sso', { path: '/', maxAge: 31536000 });
        isAuthenticated = true;
      } else {
        /**/
        msalInstance.loginRedirect(loginRequest).catch(e => {
          console.log(e)
        })

      }
    } else {
      isAuthenticated = true;
    }
  }
  return (

    <div>{!mockup ?
      (isAuthenticated ?

        <BrowserRouter>
          <MyRoutes />
        </BrowserRouter> : <React.Fragment>
          <div className="navbar ">
            <div className="navbar_container flex">
              <div className="navbar_logo"></div>
            </div>
          </div>
          <div Style={"text-align: -webkit-center; margin-top: 10%;"} className='traceability_what'>You are not authorized.</div>
        </React.Fragment>)
      :
      <BrowserRouter>
        <MyRoutesMockup />
      </BrowserRouter>}
    </div>
  );


}

export default App;
