import CenterComponent from './CenterComponent'
import Footer from './Footer'
import React, { useEffect, useState } from 'react';
import Navbar from './Navbar'
import { DigitalPassportService } from '../services/Services'

export default function Homepage(props) {

    const [selected, setSelected] = React.useState(window.location.pathname && window.location.pathname.split('/')[1] ? 'null' : 'search')
    const [product_ind, setProductInd] = React.useState(0)
    const [product, setProduct] = React.useState([])
    const [loading, setLoading] = React.useState(false)


    const changeComponent = (value, product_ind) => {
        setProductInd(product_ind)
        setSelected(value)
        window.history.pushState({ page: 'home_page' }, 'home_page', '/')
        //}
    }
    return (
        <div Style={" width: 100%;"}> {/* background-color: #f6f6f6; */}
            {selected === 'null' ? '' : <Navbar changeComponent={changeComponent} />}
            {/* */}
            <CenterComponent product_ind={product_ind} selected={selected} changeComponent={changeComponent} loading={loading} setLoading={setLoading} />
            {selected === 'null' ? '' : <Footer />}
            {selected === 'null' ? <div className="loader-cont">
                <div className="loader1"></div>
            </div> : ''}
        </div>
    )
}