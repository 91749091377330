import ScrollContainer from 'react-indiana-drag-scroll';
import React from 'react';
import { useEffect } from 'react';
export default function ProductSection(props) {

    const [openTab, setOpenTab] = React.useState('')
    const [category, setCategory] = React.useState('')
    const [load, setLoad] = React.useState(false)

    const selectTab = (val) => {
        let a = document.getElementsByTagName('body')
        if (a[0])
            a[0].style.height = '100vh'
        setOpenTab(true)
        setCategory(val)

    }

    const setLoading = async (val) => {
        setLoad(val)
    }

    const switchCategory = (value) => {
        if (value !== category) {

            setCategory(value)
            setLoading(true).then(() => {

                setTimeout(() => {
                    setLoad(false)

                }, 200);
            })
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const closeTab = () => {
        let a = document.getElementsByTagName('body')
        if (a[0])
            a[0].style.height = 'auto'
        setOpenTab(false)
    }

    let product_images = props.product.images ? props.product.images.map((i) => <div className='product_image_card' Style={'background-image:url(' + i + ')'}></div>) : ''

    let composition =
        <div>
            <div Style={"margin-bottom: 15px; font-family: 'GucciSansPro-Medium'; text-transform: uppercase;"}>Composition</div>
            <div className='composition_table_container'>
                <table>
                    <tr className='composition_table_row'>
                        <th className='composition_table_header'>Part</th>
                        <th className='composition_table_header'>Material</th>
                        <th className='composition_table_header'>Composition</th>
                    </tr>
                    {props.product.composition ? props.product.composition.map((comp) =>
                        <tr className='composition_table_row'>
                            <td className='composition_table_cell'>{comp.part}</td>
                            <td className='composition_table_cell'>{comp.material}</td>
                            <td className='composition_table_cell'>{comp.composition}</td>
                        </tr>
                    ) : ''}
                </table>
            </div>
        </div>

    let traceability =
        <div>
            <div Style={"margin-bottom: 15px; font-family: 'GucciSansPro-Medium'; text-transform: uppercase;"}>Traceability</div>
            {props.product.traceability ? props.product.traceability.map((trace) =>
                <div className='flex'>
                    <div className='traceability_what'>{trace.what}</div>
                    <div className='traceability_where'>{trace.where}</div>
                </div>
            ) : ''}
        </div>

    let circular = props.product.circular && props.product.circular[0] ?

        <div>
            <div Style={"margin-bottom: 15px; font-family: 'GucciSansPro-Medium'; text-transform: uppercase;"}>Circular</div>
            <div className='composition_text'>{props.product.circular[0].main}</div>
            <div Style={"margin-top: 20px; font-family: 'GucciSansPro-Medium'"}>Care istrucions:</div>
            <div className='composition_text'>{props.product.circular[0].care_istruction[0].main}</div>
            <div Style={"margin-top: 10px;"}>
                {props.product.circular[0].care_istruction[0].istructions.map((istruction) =>
                    <div className='composition_text'>{istruction}</div>)}
            </div>
            <div Style={"margin-top: 20px; font-family: 'GucciSansPro-Medium'"}>Repair services:</div>
            <div Style={"margin-top: 10px;"}>
                {props.product.circular[0].repair_services.map((services) =>
                    <div className='composition_text'>{services}</div>)}
            </div>
            {props.product.circular[0].resale_options ?
                <React.Fragment>
                    <div Style={"margin-top: 20px; font-family: 'GucciSansPro-Medium'"}>Resale options:</div>
                    <div Style={"margin-top: 10px;"}>
                        {props.product.circular[0].resale_options.map((options) =>
                            <div className='composition_text'>{options}</div>)}
                    </div>
                </React.Fragment>
                : ''}
            <div Style={"padding-bottom: 80px;"}></div>
        </div>
        : ''

    let packaging = props.product.packaging ?
        <div>
            <div Style={"margin-bottom: 15px; font-family: 'GucciSansPro-Medium'; text-transform: uppercase;"}>Packaging</div>
            <div>
                <div Style={"font-family: 'GucciSansPro-Medium'"}>Product packaging:</div>
                {props.product.packaging[0].product_packaging.map((pack) =>
                    <div className='composition_text'>{pack}</div>
                )}
            </div>
            <div Style={"margin-top: 20px;"}>
                <div Style={"font-family: 'GucciSansPro-Medium'"}>Packaging for e-commerce purchases:</div>
                {props.product.packaging[0].ecommerce.map((pack) =>
                    <div>{pack}</div>
                )}
            </div>
            <div>
                <div Style={"font-family: 'GucciSansPro-Medium'; margin-bottom: 15px; margin-top: 30px;"}>SORTING INFORMATION</div>
                <div Style={"font-family: 'GucciSansPro-Medium'; margin-bottom: 15px;"}>Italy:</div>
                <div className='packaging_table_container' Style={"margin-bottom: 15px;"} >
                    <table>
                        <tr className='composition_table_row'>
                            <td className='composition_table_cell'>scatola/box</td>
                            <td className='composition_table_cell'>PAP 21</td>
                            <td className='composition_table_cell'>carta/paper</td>
                        </tr>
                        <tr className='composition_table_row'>
                            <td className='composition_table_cell'>carta velina/tissue paper</td>
                            <td className='composition_table_cell'>PAP 22</td>
                            <td className='composition_table_cell'>carta/paper</td>
                        </tr>
                        <tr className='composition_table_row'>
                            <td className='composition_table_cell'>nastro/ribbon</td>
                            <td className='composition_table_cell'>TEX 60</td>
                            <td className='composition_table_cell'>carta/paper</td>
                        </tr>
                        <tr className='composition_table_row'>
                            <td className='composition_table_cell'>scatola/box</td>
                            <td className='composition_table_cell'>PAP 22</td>
                            <td className='composition_table_cell'>sacchetto/shopping bag</td>
                        </tr>
                        <tr className='composition_table_row'>
                            <td colSpan={3} className='composition_table_cell'>Raccolta differenziata - verifica le disposizioni del tuo comune<br />
                                Separate collection - check your local collection's guidelines</td>
                        </tr>
                    </table>
                </div>
                <div Style={"font-family: 'GucciSansPro-Medium'; margin-bottom: 15px;"}>France:</div>
                <div id="france_packaging" Style={"margin-bottom: 15px;"}></div>
                <div Style={"font-family: 'GucciSansPro-Medium'; margin-bottom: 15px;"}>Japan:</div>
                <div id="japan_packaging"></div>
            </div>
        </div> : ''

    return (
        <div className="product_section">
            <div className='product_section_container'>
                <div className={openTab ? 'tab' : (openTab === '' ? '' : 'closed_tab none')}>

                    {openTab === '' ? '' :
                        <React.Fragment>
                            <div className='close_button'>
                                <svg onClick={() => closeTab()} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x-circle-fill pointer" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                                </svg>
                            </div>
                            <div className='flex'>
                                <div className='left_tab'>
                                </div>
                                <div className='right_tab'>
                                    <div className='right_tab_container'>
                                        <div className='left_menu_tab'>
                                            <div className={category === 'composition' ? "tab_menu tab_menu_selected" : "tab_menu"} onClick={() => switchCategory('composition')}>Composition</div>
                                            <div className={category === 'traceability' ? "tab_menu tab_menu_selected" : "tab_menu"} onClick={() => switchCategory('traceability')}>Traceability</div>
                                            <div className={category === 'circular' ? "tab_menu tab_menu_selected" : "tab_menu"} onClick={() => switchCategory('circular')}>Circular Gucci</div>
                                            <div className={category === 'packaging' ? "tab_menu tab_menu_selected" : "tab_menu"} onClick={() => switchCategory('packaging')}>Packaging</div>
                                        </div>
                                        <div className='right_menu_tab'>
                                            <div className={!load ? 'right_menu_tab_cont' : ''}>
                                                {!load ?
                                                    <React.Fragment>
                                                        {category === 'composition' ? composition : ''}
                                                        {category === 'traceability' ? traceability : ''}
                                                        {category === 'circular' ? circular : ''}
                                                        {category === 'packaging' ? packaging : ''}
                                                    </React.Fragment> : ''}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </React.Fragment>}
                </div>
                <div className='product_description_container'>
                    <div className='flex'>
                        <div className="left_description">
                            {/*
                    <div className="product_auth">THIS IS AN AUTHENTIC GUCCI ITEM</div>
                    <div className="product_auth">PRODUCT SHEET RELATING TO ENVIRONMENTAL QUALTIES OR CHARACTERISTIC</div>
                    */}
                            <div className="product_name">{props.product.name}</div>
                            <div className="product_code">{props.product.id}</div>
                            <div className="product_description">{props.product.description}</div>
                            <div className="point_list_container">
                                <div className="flex fit" Style={"align-items: center; margin-bottom: 2px; "} onClick={() => selectTab('composition')}>
                                    <div className="point_list"><img src={require('../resources/img/add.png')} width='11px' /></div>
                                    <div className="point_name">Composition</div>
                                </div>
                                <div className="flex fit" Style={"align-items: center; margin-bottom: 2px;"} onClick={() => selectTab('traceability')}>
                                    <div className="point_list"><img src={require('../resources/img/add.png')} width='11px' /></div>
                                    <div className="point_name">Traceability</div>
                                </div>
                                <div className="flex fit" Style={"align-items: center; margin-bottom: 2px;"} onClick={() => selectTab('circular')}>
                                    <div className="point_list"><img src={require('../resources/img/add.png')} width='11px' /></div>
                                    <div className="point_name">Circular Gucci</div>
                                </div>
                                <div className="flex fit" Style={"align-items: center; margin-bottom: 2px;"} onClick={() => selectTab('packaging')}>
                                    <div className="point_list"><img src={require('../resources/img/add.png')} width='11px' /></div>
                                    <div className="point_name">Packaging</div>
                                </div>
                            </div>
                            <div className='hr_top_container'>
                                <hr></hr>
                            </div>

                            <div className="nfc_global_container flex">
                                {props.product.nfc ?
                                    <React.Fragment>
                                        <div>
                                            {/* <div Style={"font-family: 'GucciSansPro-Medium'; margin-top: 10px; font-size: 12px;"} >GUCCI AUTHENTICITY TAG</div>*/}
                                            <div className='nfc_container'>
                                                <div id="nfc_stamp_logo" className="pointer" onClick={() => window.open('https://tag.gucci.com/', '_blank')}></div>
                                                <div className='nfc_text_container' Style={"font-family: 'GucciSansPro-Book';"}>
                                                    This product is embedded with <span className='pointer' onClick={() => window.open('https://tag.gucci.com/', '_blank')}>NFC technology</span> - an innovative way to authenticate and experience your item digitally
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                    : ''}
                            </div>


                            <div className='hr_bottom_container'>
                                <hr></hr>
                            </div>
                            <div className="export_button pointer">EXPORT</div>
                            {/*
                            <div className="product_auth">history</div>
                            <div className="product_code_link" onClick={() => props.selectProduct(0)}>750396-UAAAY-1092</div>
                            <div className="product_code_link" onClick={() => props.selectProduct(1)}>746939-AAB62-5343</div>
                            <div className="product_code_link" onClick={() => props.selectProduct(2)}>676610-4G200-1061</div>
                            <div className="product_code_link" onClick={() => props.selectProduct(3)}>677810-4H625-4587</div>
                            */}
                        </div>
                        <div className="right_carousel">
                            <ScrollContainer className='scroll_cont' hideScrollbars={false}>
                                {product_images}
                            </ScrollContainer>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}