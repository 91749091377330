import { Component } from 'react';
import axios from 'axios';
import { msalInstance, loginRequest, msalConfig } from '../authConfig';
import Cookies from 'universal-cookie';

//var BASE_URL = 'https://ficheproduit.gucci.com/api'
var EXPORT_URL = 'https://ficheproduit.gucci.com/api'
var BASE_URL = 'https://digitalpassport-api.globaltech.gucci'
var cookies = new Cookies();

const getToken = () => {
    return new Promise(function (resolve, reject) {
        msalInstance.acquireTokenSilent({
            ...loginRequest,
            ...msalConfig.auth.authority,
            account: msalInstance.getAllAccounts()[0]
        }).then((response) => {
            cookies.set('user_token', response.idToken, { path: '/', maxAge: 31536000 });
            cookies.set('user_token_type', 'sso', { path: '/', maxAge: 31536000 });
            resolve(response.idToken);
        }).catch(function (error) {
            resolve(cookies.get("user_token"));
        });
    })
}
export class DigitalPassportService extends Component {


    async getItems(smc) {
        let responseStatus = ''
        return new Promise(async (resolve, reject) => {
            let c = smc.replaceAll("-", "")
            fetch(BASE_URL + '/v1/pim/sustainability/digitalpassport?product_code=' + c, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': await getToken()
                }
            }).then(response => {
                responseStatus = response.status
                return response.json()
            })
                .then(data => {
                    if (data.status === "OK") {
                        resolve(data)
                    } else {
                        reject()
                    }
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }

    async exportExcel(smc) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.get(EXPORT_URL + '/product/' + smc + '/export', { responseType: 'blob' }).then((res) => {
                //axios.get(BASE_URL + '/product/' + smc + '/export', { responseType: 'blob' }).then((res) => {

                const type = 'application/pdf'
                const blob = new Blob([res.data], { type: type })
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = smc + '.pdf'
                link.click()
                resolve()

            }).catch((error) => {
                reject(error)
            })

        })
    }

}
