import { Route, Routes, useNavigate } from "react-router-dom";
import Homepage_mockup from "./mockup/Homepage";

const MyRoutesMockup = () => {

    /*
    const history = useNavigate();
    const originalUri = async (_oktaAuth, originalUri) => {
        history(toRelativeUrl(originalUri || "/", window.location.origin));
    };
    */

    return (

        <Routes >
            <Route path="/mockup" element={<Homepage_mockup />} />
            <Route path="/mockup/:id" element={<Homepage_mockup />} />
        </Routes>

    );
};
export default MyRoutesMockup;