import CenterComponent from './CenterComponent'
import Footer from './Footer'
import React from 'react'
import Navbar from './Navbar'

export default function Homepage(props) {
    const [selected, setSelected] = React.useState('search')
    const [product_ind, setProductInd] = React.useState(0)
    const changeComponent = (value, product_ind) => {
        setProductInd(product_ind)
        setSelected(value)
        //if (value === 'search') {
        window.history.pushState({ page: 'home_page' }, 'home_page', '/mockup')
        //}
    }

    return (
        <div Style={" width: 100%;"}> {/* background-color: #f6f6f6; */}
            <Navbar changeComponent={changeComponent} />
            {/* */}
            <CenterComponent product_ind={product_ind} selected={selected} changeComponent={changeComponent} />
            <Footer />
        </div>
    )
}