import React from 'react'
import ScrollContainer from 'react-indiana-drag-scroll';

export default function OtherProductsSection(props) {

    let products = []
    props.product.other_products_items.map((p) =>
        products.push({
            "link": p.product_page,
            "image_url": p.image_link
        })
    )

    let products_view = ''
    if (products.length > 0) {
        products_view =
            products.map((p) =>
                <div className='carousel_product' Style={"background-image: url('" + p.image_url + "')"} onClick={() => window.open(p.link, '_blank')}></div>
            )
    }

    let carousel_products = <div className='flex also_like_carousel'>{products_view}</div>

    return (
        <div className="also_like_component">
            <div className='also_like'>
                <div className="also_like_container">

                    <div id="also_like_title">You might also like</div>
                    <div className='also_like_carousel_container'>
                        <ScrollContainer className='scroll_cont' hideScrollbars={false}>
                            {carousel_products}
                        </ScrollContainer>
                    </div>
                </div>
            </div>
        </div>
    )
}