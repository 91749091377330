import React, { useRef } from 'react'


export default function VideoSection(props) {

    const src = props.product.video_link
    const vidRef = useRef(null);
    const handlePlayVideo = () => {
        vidRef.current.play();
    }

    return (
        <div className='video_container' Style={"text-align: -webkit-center;"}>
            <div id="video_title">Not just a bag, but a piece of history</div>
            <div id="video_subtitle">The making of the Iconic Gucci horsebit</div>
            <div className='video_player_container'>
                <video width="100%" ref={vidRef} autoPlay={true} muted={true} controls loop={true} playsInline>
                    <source src={src} type="video/mp4" />
                </video>
            </div>
        </div>
    )
}