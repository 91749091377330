import { useEffect } from "react";
import OtherProductsSection from "./OtherProductsSection";
import ProductSection from "./ProductSection";
import SearchComponent from "./SearchComponent";
import VideoSection from "./VideoSection";
import React from "react";

export default function CenterComponent(props) {

    /* */
    const products = [{
        name: 'HANDBAG GUCCI DIANA SOFTY WONK',
        id: '750396-UAAAY-1092',
        description: 'Speaking to the narrative of evolution and reinvention that runs through Gucci’s designs, the Gucci Diana reimagines a bamboo tote from the ’90s archives.Crafted in soft black leather, this spacious silhouette reveals two leather handles that can be folded into the interior, allowing versatility in styling it as a shoulder bag or top handle.',
        images: ['https://ficheproduit.gucci.com/mockup/images/750396-UAAAY-1092.jpg'],
        composition: [
            { part: 'Main outer material', material: 'Leather', composition: '100% leather made through a metal-free or chrome-free tanning process' },
            { part: 'Lining', material: 'Fabrics', composition: '60% recycled nylon' },
            { part: 'Accessories', material: 'Metal', composition: '45% recycled metal' },
            { part: 'Accessories', material: 'Bamboo', composition: '' },
            { part: 'Other outer material', material: 'Fabrics', composition: '' },
            { part: 'Other outer material', material: 'Leather', composition: '100% leather made through a metal-free or chrome-free tanning process' },
            { part: 'Reinforcements and internal components', material: 'Leather', composition: '100% leather made through a metal-free or chrome-free tanning process' },
            { part: 'Reinforcements and internal components', material: 'Fabrics', composition: '' },
            { part: 'Thread', material: 'Yarn', composition: '100% reclyed polyester' },
        ],
        traceability: [{ what: 'Cutting:', where: 'Italy' }, { what: 'Assembly:', where: 'Italy' }],
        circular: [{
            main: 'Gucci’s vision for the future is to further accelerate and strengthen our work to integrate the principles of circular economy into the design and creation process while also embedding sustainability — from material choices to ensuring product longevity. In this context, through our product care and repair services, our aim is to extend a product’s life so you can enjoy your item again and again.',
            care_istruction: [{
                main: 'Gucci products are made with carefully selected materials. Please handle with care for longer product life.',
                istructions: [
                    '- Protect from direct light, heat and rain. Should it become wet, dry it immediately with a soft cloth',
                    '- Fill the bag with tissue paper to help maintain its shape and absorb humidity, and store in the provided flannel bag',
                    '- Do not carry heavy products that may affect the shape of the bag',
                    '- Clean with a soft, dry cloth'
                ]
            }],
            repair_services: [
                'Gucci provides product care and repair services for products purchased from Gucci.com, Gucci stores and authorized Gucci retailers.',
                'Our product care and repair services start with an initial evaluation of the item’s conditions. Depending on the specific request, a Gucci Client Advisor will be able to advise you on the suitable solutions and will keep you informed throughout the process.'
            ],
            resale_options: [
                'Extending the life of a Gucci product contributes to the House’s vision of a circular future for fashion, and now, as an exclusive service for our ever-growing, eco-conscious clientele, we have partnered with Vestiaire Collective in a new take-back program. If you are the owner of a handbag from one of our signature lines*, you are now able to exchange it for store credit, either through an elevated in-person experience in selected Gucci boutiques or online.',
                '* The lines include the GG Marmont, Dionysus, Gucci Horsebit 1955, Jackie 1961, Gucci Diana, Gucci Bamboo 1947, Ophidia, Padlock, and Sylvie.'
            ]
        }],
        packaging: [
            {
                product_packaging:
                    [
                        '- Dustbag: 100% organic cotton (ribbon 100% recycled polyester)',
                        '- Box and tissue paper: 100% paper from resposible sources; minimum 85% recycled content',
                        '- Ribbon: 100% organic cotton',
                        '- Shopping bag: 100% paper from resposible sources; minimum 85% recycled content'
                    ],
                ecommerce: [
                    '- Tote bag: 100% cotton',
                    '- Outerbox: 100% paper from resposible sources; minimum 85% recycled content'
                ]
            }
        ],
        nfc: true,
        other_products: true,
        other_products_items: [
            { image_link: 'https://ficheproduit.gucci.com/mockup/images/might-also-like/707449 21HRG 2687.jpg', product_page: 'https://www.gucci.com/uk/en_gb/pr/women/handbags/clutches-evening-bags-for-women/gucci-diana-mini-tote-bag-p-70744921HRG2687' },
            { image_link: 'https://ficheproduit.gucci.com/mockup/images/might-also-like/724667 UAAAY 1092.jpg', product_page: 'https://www.gucci.com/uk/en_gb/pr/women/handbags/shoulder-bags-for-women/gucci-diana-mini-bucket-bag-p-724667UAAAY1092' },
            { image_link: 'https://ficheproduit.gucci.com/mockup/images/might-also-like/678842 U3ZDT 2185.jpg', product_page: 'https://www.gucci.com/uk/en_gb/pr/women/handbags/totes-bags-for-women/gucci-diana-medium-tote-bag-p-678842U3ZDT2185' },
            { image_link: 'https://ficheproduit.gucci.com/mockup/images/might-also-like/658244 17Q0T 1000.jpg', product_page: 'https://www.gucci.com/uk/en_gb/pr/women/wallets-and-small-accessories-for-women/card-holder-small-accessories/card-holders-coin-cases-for-women/gucci-diana-card-case-wallet-p-65824417Q0T1000' },
            { image_link: 'https://ficheproduit.gucci.com/mockup/images/might-also-like/719830 1DO50 1000.jpg', product_page: 'https://www.gucci.com/uk/en_gb/pr/women/shoes-for-women/boots-and-ankle-boots-for-women/ankle-boots-for-women/womens-boot-with-horsebit-p-7198301DO501000' },
        ],
        video_section: true,
        video_link: 'https://ficheproduit.gucci.com/mockup/videos/hbgs.mp4'
    }, {
        name: 'GUCCI RUN PREMIUM',
        id: '746939-AAB62-5343',
        description: 'Designs inspired by the sporting world are interpreted through the Gucci lens with standout logo details. This pair of streamlined sneakers is crafted from lilac suede and enriched with a bi-colour rubber sole and an Interlocking G detail.',
        images: ['https://ficheproduit.gucci.com/mockup/images/746939-AAB62-5343.jpg'],
        other_products: true,
        other_products_items: [
            { image_link: 'https://ficheproduit.gucci.com/mockup/images/might-also-like/749909 AAB79 9152.jpg', product_page: 'https://www.gucci.com/uk/en_gb/pr/women/shoes-for-women/sneakers-for-women/low-top-trainers-for-women/womens-mac80-sneakers-p-749909AAB799152' },
            { image_link: 'https://ficheproduit.gucci.com/mockup/images/might-also-like/746939 AAB62 9144.jpg', product_page: 'https://www.gucci.com/uk/en_gb/pr/women/shoes-for-women/sneakers-for-women/low-top-trainers-for-women/womens-gucci-run-sneaker-p-746939AAB629144' },
            { image_link: 'https://ficheproduit.gucci.com/mockup/images/might-also-like/714660 USM10 8442.jpg', product_page: 'https://www.gucci.com/uk/en_gb/pr/women/shoes-for-women/sneakers-for-women/womens-gucci-run-sneaker-p-714660USM108442' },
            { image_link: 'https://ficheproduit.gucci.com/mockup/images/might-also-like/723566 XJFQQ 9088.jpg', product_page: 'https://www.gucci.com/uk/en_gb/st/newsearchpage?searchString=723566%20XJFQQ%209088&search-cat=header-search' },
            { image_link: 'https://ficheproduit.gucci.com/mockup/images/might-also-like/523155 UKMBG 8481.jpg', product_page: 'https://www.gucci.com/uk/en_gb/pr/women/wallets-and-small-accessories-for-women/card-holder-small-accessories/card-holders-coin-cases-for-women/ophidia-jumbo-gg-card-case-p-523155UKMBG8481' },
        ],
        video_section: false,
        composition: [
            { part: 'Main outer material', material: 'Leather', composition: '100% leather made through a metal-free or chrome-free tanning process' },
            { part: 'Other materials of the upper', material: 'Fabrics', composition: '' },
            { part: 'Other materials of the upper', material: 'Twill', composition: '100% recycled polyester' },
            { part: 'Sole', material: 'Plastic', composition: '20% bio-based content (minimum)' },
            { part: 'Internal materials contain', material: 'Leather', composition: '' },
            { part: 'Other outer material', material: 'Leather', composition: '100% leather made through a metal-free or chrome-free tanning process' },
            { part: 'Reinforcements and internal components', material: 'Leather', composition: '' },
            { part: 'Reinforcements and internal components', material: 'Twill', composition: '' },
            { part: 'Laces', material: 'Yarn', composition: '50% organic cotton' },
            { part: 'Thread', material: 'Yarn', composition: '100% reclyed polyester' },
        ],
        traceability: [{ what: 'Cutting:', where: 'Italy' }, { what: 'Finishing:', where: 'Italy' }, { what: 'Stiching:', where: 'Italy' }],
        circular: [{
            main: 'Gucci’s vision for the future is to further accelerate and strengthen our work to integrate the principles of circular economy into the design and creation process while also embedding sustainability — from material choices to ensuring product longevity. In this context, through our product care and repair services, our aim is to extend a product’s life so you can enjoy your item again and again.',
            care_istruction: [{
                main: 'Gucci products are made with carefully selected materials. Please handle with care for longer product life.',
                istructions: [
                    '- Protect from direct light, heat and rain. Should it become wet, dry it immediately with a soft cloth',
                    '- Fill shoe with tissue paper to help maintain the shape and absorb humidity, then store in the provided flannel bag and box',
                    '- Clean with a soft, dry cloth or brush',]
            }],
            repair_services: [
                'Gucci provides product care and repair services for products purchased from Gucci.com, Gucci stores and authorized Gucci retailers.',
                'Our product care and repair services start with an initial evaluation of the item’s conditions. Depending on the specific request, a Gucci Client Advisor will be able to advise you on the suitable solutions and will keep you informed throughout the process.'
            ]
        }],
        packaging: [{
            product_packaging:
                [
                    '- Dustbag: 100% organic cotton (ribbon 100% recycled polyester)',
                    '- Box and tissue paper: 100% paper from resposible sources; minimum 85% recycled content',
                    '- Ribbon: 100% organic cotton',
                    '- Shopping bag: 100% paper from resposible sources; minimum 85% recycled content'
                ],
            ecommerce: [
                '- Tote bag: 100% cotton',
                '- Outerbox: 100% paper from resposible sources; minimum 85% recycled content'
            ]
        }],
        nfc: false
    }, {
        name: 'GG jacquard pattern knit scarf with tassels',
        id: '676610-4G200-1061',
        description: 'The front and back GG pattern in reverse colours adds a playful note to this soft jacquard wool scarf. The monogram motif dates back to the 1970s, recalling the initials of the founder Guccio Gucci. Here it is presented in a contrasting shade of grey, while black tassels complete the style.',
        images: ['https://ficheproduit.gucci.com/mockup/images/676610-4G200-1061.jpg'],
        other_products: true,
        other_products_items: [
            { image_link: 'https://ficheproduit.gucci.com/mockup/images/might-also-like/674275 4GABX 1360.jpg', product_page: 'https://www.gucci.com/uk/en_gb/pr/men/accessories-for-men/scarves-for-men/gg-cashmere-jacquard-scarf-p-6742754GABX1360' },
            { image_link: 'https://ficheproduit.gucci.com/mockup/images/might-also-like/676827 4GABX 1360.jpg', product_page: 'https://www.gucci.com/uk/en_gb/pr/men/accessories-for-men/hats-and-gloves-for-men/beanies-for-men/gg-knit-cashmere-hat-p-6768274GABX1360' },
            { image_link: 'https://ficheproduit.gucci.com/mockup/images/might-also-like/726586 4GABX 1360.jpg', product_page: 'https://www.gucci.com/uk/en_gb/pr/women/accessories-for-women/hats-and-gloves-for-women/gloves-for-women/gg-cashmere-fingerless-gloves-p-7265864GABX1360' },
            { image_link: 'https://ficheproduit.gucci.com/mockup/images/might-also-like/716334 XKCOZ 4804.jpg', product_page: 'https://www.gucci.com/uk/en_gb/pr/men/ready-to-wear-for-men/knitwear-for-men/cardigans-for-men/gg-check-knit-wool-cardigan-p-716334XKCOZ4804' },
            { image_link: 'https://ficheproduit.gucci.com/mockup/images/might-also-like/724642 9C2SN 4076.jpg', product_page: 'https://www.gucci.com/uk/en_gb/pr/men/bags-for-men/duffle-bags-for-men/gucci-savoy-small-duffle-bag-p-7246429C2SN4076' },
        ],
        video_section: true,
        video_link: 'https://ficheproduit.gucci.com/mockup/videos/la_soledad_farm.mp4',
        composition: [{ part: 'Main Fabric', material: 'Fabrics', composition: '100% wool from GUCCI Nativa regenerative farming program' }],
        traceability: [{ what: 'Knitting and waving:', where: 'Italy' }, { what: 'Dyeing and printing:', where: 'Italy' }, { what: 'Cutting and sewing:', where: 'Italy' }],
        circular: [{
            main: 'Gucci’s vision for the future is to further accelerate and strengthen our work to integrate the principles of circular economy into the design and creation process while also embedding sustainability — from material choices to ensuring product longevity. In this context, through our product care and repair services, our aim is to extend a product’s life so you can enjoy your item again and again.',
            care_istruction: [{
                main: 'Gucci products are made with carefully selected materials. Please handle with care for longer product life.',
                istructions: [
                    '- Dry clean only',
                    '- If needed, iron on low temperature to remove any wrinkles or creases'
                ]
            }],
            repair_services: [
                'Gucci provides product care and repair services for products purchased from Gucci.com, Gucci stores and authorized Gucci retailers.',
                'Our product care and repair services start with an initial evaluation of the item’s conditions. Depending on the specific request, a Gucci Client Advisor will be able to advise you on the suitable solutions and will keep you informed throughout the process.'
            ]
        }],
        packaging: [{
            product_packaging:
                [
                    '- Box and tissue paper: 100% paper from resposible sources; minimum 85% recycled content',
                    '- Ribbon: 100% organic cotton',
                    '- Shopping bag: 100% paper from resposible sources; minimum 85% recycled content'
                ],
            ecommerce: [
                '- Tote bag: 100% cotton',
                '- Outerbox: 100% paper from resposible sources; minimum 85% recycled content'
            ]
        }],
        nfc: false
    }]


    const [notFound, setNotFound] = React.useState(false)
    const [product, setProduct] = React.useState(products[props.product_ind < products.length ? props.product_ind : 0])
    const [notFoundSmc, setNotFoundSmc] = React.useState('')

    /*
    useEffect(() => {
        window.history.pushState(null, null, window.location.href);
        window.addEventListener('popstate', handleBackButtonEvent)
    }, [])

    const handleBackButtonEvent = (e) => {
        e.preventDefault();
        e.stopPropagation()
        e.stopImmediatePropagation()
        console.log('mokcup')
        // window.history.pushState({ page: 'state' }, 'state', window.location.href.split('/')[0] + '/mockup/search');
    }
    */

    useEffect(() => {

        if (props.product_ind && props.product_ind >= products.length) {
            props.changeComponent('search', 0)
            setNotFound(true)
        } else
            if (window.location.pathname.split('/')[2]) {
                let found = false
                products.map((p, i) => {
                    if (p.id == window.location.pathname.split('/')[2]) {
                        selectProduct(i, p.id)
                        found = true
                    }
                })
                if (!found) {
                    setNotFound(true)
                    setNotFoundSmc(window.location.pathname.split('/')[2])
                }

            }
    }, [props.product_ind])

    const selectProduct = (ind, id) => {
        if (ind >= products.length) {
            props.changeComponent('search', 0)
            setNotFound(true)
            setNotFoundSmc(id)
        } else {
            props.changeComponent('product_code', ind)
            setProduct(products[ind])
            if (!window.location.pathname.split('/')[2] || window.location.pathname.split('/')[2] === '' || window.location.pathname.split('/')[2] !== products[ind].id)
                window.history.pushState({ page: 'display_page' }, 'display_page', '/mockup/' + products[ind].id);
        }
    }


    return (

        props.selected === 'search' ?
            <SearchComponent changeComponent={props.changeComponent} notFound={notFound} notFoundSmc={notFoundSmc} /> :

            <div className="home_component">
                <ProductSection product={products[props.product_ind < products.length ? props.product_ind : 0]} selectProduct={selectProduct} product_ind={props.product_ind} product_code={props.selected} changeComponent={props.changeComponent} setNotFound={setNotFound} />
                {products[props.product_ind].video_section ?
                    <VideoSection product={products[props.product_ind < products.length ? props.product_ind : 0]} /> : ''}

                {products[props.product_ind].other_products ?
                    <OtherProductsSection product={products[props.product_ind < products.length ? props.product_ind : 0]} /> : ''}
            </div>


    )
}