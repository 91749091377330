import { useEffect } from "react";
import OtherProductsSection from "./OtherProductsSection";
import ProductSection from "./ProductSection";
import SearchComponent from "./SearchComponent";
import VideoSection from "./VideoSection";
import React from "react";
import { DigitalPassportService } from '../services/Services'

export default function CenterComponent(props) {

    const [dpS] = React.useState(new DigitalPassportService())
    const [loading, setLoading] = React.useState(false)
    const [notFoundSmc, setNotFoundSmc] = React.useState('')

    /* */
    const products = []
    const [notFound, setNotFound] = React.useState(false)
    const [product, setProduct] = React.useState(products[props.product_ind < products.length ? props.product_ind : 0])

    /*
    useEffect(() => {
        window.history.pushState(null, null, window.location.href);
        window.addEventListener('popstate', handleBackButtonEvent);
    }, [])

    const handleBackButtonEvent = (e) => {
        e.preventDefault();
        e.stopPropagation()
        e.stopImmediatePropagation()
        window.history.pushState(null, null, window.location.href.split('/')[0] + '/search');
    }
    */

    useEffect(() => {

        if (window.location.pathname.split('/')[1]) {
            getItem(window.location.pathname.split('/')[1])
        }

    }, [])

    const getItem = (smc) => {
        setLoading(true)
        dpS.getItems(smc).then((data) => {
            if (data) {
                props.changeComponent('product_code', smc)
                window.history.pushState({ page: "display_page" }, "display_page", '/' + smc);
                setProduct(data)
            }
            setLoading(false)
        }).catch(() => {
            props.changeComponent('search', 0)
            setNotFoundSmc(smc)
            setNotFound(true)
            setLoading(false)
        })

    }

    const selectProduct = (ind) => {
        if (ind >= products.length) {
            props.changeComponent('search', 0)
            setNotFound(true)
        } else {
            props.changeComponent('product_code', ind)
            setProduct(products[ind])
            if (!window.location.pathname.split('/')[1] || window.location.pathname.split('/')[1] === '' || window.location.pathname.split('/')[1] !== products[ind].id)
                window.history.pushState({ page: "display_page" }, "display_page", '/' + products[ind].style_color_code);
        }
    }


    return (

        props.selected === 'search' ?

            <SearchComponent changeComponent={props.changeComponent} notFound={notFound} getItem={getItem} setLoading={setLoading} loading={loading} notFoundSmc={notFoundSmc} />
            :
            (props.selected === 'null' ? '' :
                <div className="home_component">
                    {loading ?
                        <div className="loader-cont">
                            <div className="loader1"></div>
                        </div>
                        :
                        <React.Fragment>
                            <ProductSection product={product} selectProduct={selectProduct} product_ind={props.product_ind} product_code={props.selected} changeComponent={props.changeComponent} setNotFound={setNotFound} />
                            {product.video_section ?
                                <VideoSection product={product} /> : ''}
                            {product.other_products ?
                                <OtherProductsSection product={product} /> : ''}
                        </React.Fragment>
                    }
                </div>)


    )
}