import { Route, Routes } from "react-router-dom";
import Homepage from "./view/Homepage";
import React from "react";

const MyRoutes = (props) => {

    return (

        <Routes >
            <Route path="/" exact={true} element={<Homepage />} />
            <Route path="/:id" exact={true} element={<Homepage />} />
        </Routes>

    );
};
export default MyRoutes;